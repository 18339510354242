
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import { plainToInstance } from 'class-transformer'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Deal, Lead } from '@/entities/crm'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import StockPrices from '@/components/toolkit/details/row/custom/StockPrices.vue'
  import AutoHeader from '@/components/toolkit/details/row/expandable/auto/header.vue'
  import Simple from '@/components/toolkit/details/row/simple.vue'
  import { Negotiation, PurchaseOrder } from '@/entities/purchase'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Process } from '@/entities/settings'
  import { fixPrice, parseToNumber, paymentIcon, stringifySafe } from '@/utils/general'
  import { PurchaseView } from '@/components/forms/view/PurchaseView'
  import { PaymentOrder } from '@/entities/finance'
  import { Document } from '@/entities/documents'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { Debounce } from '@/utils/decorators'
  import GLoading from '@/components/core/GLoading.vue'
  import { mapActions } from 'vuex'
  import logOut from '@/components/LogOut.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import ValidationPaymentComponent from '@/components/forms/validationSaleOrder/validationPaymentComponent.vue'

@Component({
  components: {
    ValidationPaymentComponent,
    GRadioButton,
    logOut,
    GLoading,
    GAlert,
    GFiles,
    Simple,
    StockPrices,
    FieldTitle,
    LinkedPerson,
    InitialFields,
    LinkedAuto,
    BaseForm,
    AutoHeader,
  },
  methods: {
    ...mapActions('resources/form', ['getNewKey']),
    paymentIcon,
  },
})
  export default class ValidatorPurchaseOrderForm extends PurchaseView {
  getNewKey!: () => Promise<number>;
  img = require('@/assets/genio/GENIO_waiting.svg')
  logo = require('@/assets/Logotipo_Interno.svg')
  loadingAirtable = false
  negotiation = plainToInstance(Negotiation, {})
  lead = plainToInstance(Lead, {})
  purchaseOrder = plainToInstance(PurchaseOrder, {})
  process: Process = plainToInstance(Process, {})
  paymentOrder: PaymentOrder[] = [plainToInstance(PaymentOrder, {})]
  document: Document = plainToInstance(Document, {})
  openPayment = false
  paymentSelected = null
  paymentPending = ''
  purchase = {
    open: false,
    title: '',
    item: null,
  }

  radioButtonMessage = {
    isLegalReport: '',
    isContract: '',
  }

  purchaseMetadata = null
  title = ''
  idProcess = null
  showDetail = false
  fileInfo = null
  panels = [1, 2]
  section = {
    interviews: false,
    documents: false,
    payment: false,
  }

  declare $refs: {
    form: HTMLFormElement
    fieldSigners: LinkedPerson
  };

  filesProcess = {
    payment: null,
  }

  metadata = {}
  formData = {
    price: null,
    client: null,
    owners: null,
    signers: null,
    buyFile: null,
    expenses: null,
    legalReport: null,
    documentType: null,
    agreedTransfer: null,
    linkContract: null,
    linkLegalReport: null,
    responsibleLetter: null,
    notarizedAuthorization: null,
    lastOrder: null,
    isLegalReport: null,
    isContract: null,
    commentLegalReport: '',
    commentContract: '',
  }

  fields = {
    price: {
      properties: {},
      rules: this.fieldRequired,
    },
    client: {
      properties: {},
      items: [],
      rules: [],
    },
    owners: {
      properties: {},
      items: [],
      rules: [],
    },
    signers: {
      properties: {},
      items: [],
      rules: [],
    },
    buyFile: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
    legalReport: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
    notarizedAuthorization: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
    documentType: {
      properties: {},
      rules: this.fieldRequired,
      items: [],
    },
    agreedTransfer: {
      properties: {},
      rules: this.fieldRequired,
      items: [],
    },
    linkLegalReport: {
      properties: {},
      rules: this.fieldRequired,
    },
    linkContract: {
      properties: {},
      rules: this.fieldRequired,
    },
    responsibleLetter: {
      properties: {},
      rules: this.fieldRequired,
    },
    expenses: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
  }

  async mounted () {
    this.loadingForm = true
    await this.setMetadata()

    const { id, uid, model, title, purchaseMetadata, parentId } = this

    if ((!isNaN(uid) && (model === 'PurchaseOrder' || model === '')) || !isNaN(id)) {
      const purchaseId = id || uid
      await this.getPurchaseInfo(purchaseId)
    }
    if (!isNaN(parentId)) {
      await this.getNegotiationInfo(parseToNumber(parentId))
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(purchaseMetadata, title, Boolean(this.purchaseOrder?.id))
    }

    const paymentProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })
    this.filesProcess.payment = paymentProcess[0].id

    this.paymentOrder = await this.fetchData({
      query: { name: 'find', model: 'PaymentOrder' },
      filter: { _and: [{ id_process_record: { _eq: this.purchaseOrder.id } }, { id_process: { _eq: this.idProcess } }] },
      force: true,
    })

    this.formData.expenses = this.paymentOrder?.[0]

    this.purchaseOrder.paymentOrder = this.paymentOrder?.[0]
    this.loadingForm = false
    await this.setDetails()
  }

  cancelPurchase () {
    const { formData } = this
    this.setBackup(null)
    const buyFile = formData?.buyFile?.length ? [...formData?.buyFile] : []
    const notarizedAuthorization = formData?.notarizedAuthorization?.length ? [...formData?.notarizedAuthorization] : []
    const legalReport = formData?.legalReport?.length ? [...formData?.legalReport] : []
    this.close([...buyFile, ...notarizedAuthorization, ...legalReport])
  }

  async getPurchaseInfo (id) {
    this.purchaseOrder = await this.fetchData({
      query: { name: 'fetch', model: 'PurchaseOrder', params: { id } },
      force: true,
    })

    this.document = await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: { id_process_record: { _eq: this.purchaseOrder.id } },
      force: true,
    })
    const { interveners } = this.document[0]

    const { agreedAmount, transferType, supportDocumentType } = this.purchaseOrder
    this.formData.price = agreedAmount
    this.formData.signers = interveners?.filter(intervener => intervener.field.name === 'sale_representative').map(intervener => intervener.person)
    this.fields.signers.items = interveners?.map(intervener => intervener.person)
    this.formData.agreedTransfer = transferType
    this.formData.documentType = supportDocumentType

    await this.getNegotiationInfo(this.purchaseOrder.negotiation.id)
  }

  async getNegotiationInfo (id) {
    if (!id) return
    this.negotiation = await this.fetchData({
      query: { name: 'fetch', model: 'Negotiation', params: { id } },
      force: true,
    })
    this.purchaseOrder.negotiation = this.negotiation
    const versionYear = await this.fetchData({
      query: { name: 'find', model: 'VersionYear' },
      filter: { id: { _eq: this.negotiation?.inspection.appraisal.deal.auto.version.id } },
      force: true,
    })
    const year = this.negotiation.createdAt.year()
    const registration = versionYear?.[0]?.registrations?.find(registration => registration.year === year)
    this.negotiation.taxAssessment = registration?.permission

    this.formData.client = this.negotiation?.inspection?.appraisal?.deal?.lead?.client
    this.fields.client.items = [this.negotiation?.inspection?.appraisal?.deal?.lead?.client]
    this.formData.owners = this.negotiation?.inspection?.appraisal?.deal?.ownership?.owners?.map(owner => owner.person)
    this.fields.owners.items = this.formData.owners
    await this.setFilesData(this.negotiation)

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'purchase_order' } } },
    })
    this.fileInfo = fileInfo
    this.setProperties(fileInfo, 'legal_report', 'legalReport')
    this.setProperties(fileInfo, 'notarized_authorization', 'notarizedAuthorization')

    if (!this.purchaseOrder?.id) {
      this.formData.price = this.negotiation.agreedAmount
      this.fields.signers.items = this.formData.owners
    }
  }

  async setFilesData (negotiation) {
    const idProcess = this.idProcess
    const processNegotiation = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'negotiation' } },
    })

    const { report, document, notarized } = await this.findAssociatedFiles(idProcess, this.purchaseOrder.id)
    this.formData.legalReport = report?.length ? report : []
    if (report?.[0]?.file?.sourceLink) {
      this.formData.linkLegalReport = report?.[0]?.file?.sourceLink
    }

    this.formData.notarizedAuthorization = notarized?.length ? notarized : []

    this.formData.buyFile = document?.length ? document : []
    if (document?.length) {
      this.formData.linkContract = document?.[0]?.file?.sourceLink
    }

    const {
      report: reportNegotiation,
    } = await this.findAssociatedFiles(processNegotiation[0].id, negotiation?.id)

    if (!report?.length) {
      this.formData.legalReport = reportNegotiation?.length ? [reportNegotiation[0]] : []
      if (reportNegotiation?.[0]?.file?.sourceLink) {
        this.formData.linkLegalReport = reportNegotiation?.[0]?.file?.sourceLink
      }
    }
  }

  async findAssociatedFiles (idProcess, id) {
    if (!id) return {}

    const report = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'legal_report' } } }] },
      force: true,
    })

    const document = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: this.purchaseOrder?.supportDocumentType?.name } } }] },
      force: true,
    })

    const notarized = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'notarized_authorization' } } }] },
      force: true,
    })

    return {
      report,
      document,
      notarized,
    }
  }

  saveBackup (item = null) {
    const { backup, formData, panels, negotiation } = this
    if (backup) {
      backup.purchaseForm = { ...formData, panels }
      backup.negotiation = negotiation
      backup.editPayment = item
      this.setBackup(backup)
    } else {
      this.setBackup({ purchaseForm: { ...formData, panels }, negotiation, editPayment: item })
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('PurchaseOrder', 'validator_purchase_order')
    const { fields, form } = metadata as Form
    this.purchaseMetadata = metadata
    this.title = form.title
    this.fields.owners.properties = fields.owners.properties
    this.fields.price.properties = fields.price.properties
    this.fields.client.properties = fields.client.properties
    Object.assign(this.fields.buyFile.properties, fields.buyFile?.properties)
    this.fields.signers.properties = fields.signers.properties
    fields.expenses.properties.appendOuterIcon = ''
    this.fields.expenses.properties = fields.expenses.properties
    Object.assign(this.fields.legalReport.properties, fields.legalReport?.properties)
    this.fields.documentType.properties = fields.documentType.properties
    this.fields.agreedTransfer.properties = fields.agreedTransfer.properties
    this.fields.linkLegalReport.properties = fields.linkLegalReport.properties
    this.fields.responsibleLetter.properties = fields.responsibleLetter.properties
    this.fields.linkContract.properties = fields.linkContract.properties
    Object.assign(this.fields.notarizedAuthorization.properties, fields.notarizedAuthorization?.properties)

    this.fields.agreedTransfer.items = await this.fetchData({
      query: { name: 'find', model: 'TransferType' },
    })
    this.fields.documentType.items = await this.fetchData({
      query: { name: 'find', model: 'SupportDocumentType' },
    })
    await this.setFilesFieldsData()
  }

  async setFilesFieldsData () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'purchase_order' } },
    })

    const { id } = process[0] as Record<string, any>

    this.idProcess = id
  }

  async setDetails () {
    const { purchaseMetadata, purchaseOrder, negotiation } = this
    const lead = negotiation?.inspection?.appraisal?.deal?.lead
    const customLead = await this.getLeadById(lead.id)

    const sale = customLead.deals?.map(deal => plainToInstance(Deal, deal))?.find(deal => deal.isSale)
    if (sale) {
      const auto = await this.fetchData({
        query: { name: 'find', model: 'Auto' },
        filter: { id: { _eq: sale?.auto?.id } },
      })

      purchaseOrder.dealSale = sale
      purchaseOrder.dealSale.auto = auto?.[0]
    }

    this.metadata = {
      data: purchaseOrder,
      metadata: purchaseMetadata,
    }

    this.showDetail = (Boolean(negotiation?.id))
  }

  sectionValidation () {
    const keys = ['isContract', 'isLegalReport']
    let response = false
    keys.forEach(key => {
      if (this.formData[key] === null) {
        this.radioButtonMessage[key] = 'Campo requerido'
        response = true
      }
    })

    return response
  }

  async send () {
    if (this.sectionValidation()) {
      return
    }
    this.loadingForm = true

    const { purchaseOrder, formData: { isContract, isLegalReport }, calculatedExpenses, purchaseOrderStatus } = this

    const isPaymentPending = calculatedExpenses.some(order => order.status.isToUpdating)

    if (isPaymentPending) {
      await this.pushData({
        model: 'PurchaseOrder',
        fields: {
          id: purchaseOrder?.id,
          id_process_status: purchaseOrderStatus.toUpdate[0].id,
          id_validator: this.idEmployee,
        },
      })
      await this.close()
      return
    }

    const isToUpdate = !isContract || !isLegalReport || calculatedExpenses.some(order => order.status.isPending)

    if (isToUpdate) {
      await this.pushData({
        model: 'PurchaseOrder',
        fields: {
          id: purchaseOrder?.id,
          id_process_status: purchaseOrderStatus.toUpdate[0].id,
          id_validator: this.idEmployee,
        },
      })
    } else {
      await this.pushData({
        model: 'PurchaseOrder',
        fields: {
          id: purchaseOrder?.id,
          id_process_status: purchaseOrderStatus.approved[0].id,
          id_validator: this.idEmployee,
        },
      })

      const pending = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'enablement' } } }, { status: { name: { _eq: 'pending' } } }] },
      })
      const newEnablement = await this.pushData({
        model: 'Enablement',
        fields: {
          id_inspection: purchaseOrder?.negotiation?.inspection?.id,
          id_process_status: pending[0].id,
        },
      })

      const enablementStatus = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'stock' } } }, { default: { _eq: true } }] },
      })
      const key = await this.getNewKey()
      await this.pushData({
        model: 'Stock',
        fields: {
          id_enablement: newEnablement.id,
          id_process_status: enablementStatus[0].id,
          id_key_identifier: key,
        },
      })

      await this.handleOtherData(purchaseOrder)
    }

    await this.handleFilesValidation()
    this.loadingForm = false
    await this.close()
  }

  async handleOtherData (purchaseOrder) {
    await this.setPaymentOrderValidator()
    await this.updateDocument(purchaseOrder?.id)
    const successful = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'successful_purchase' } } },
          { status: { process: { table_name: { _eq: 'deal' } } } },
        ],
      },
    })
    await this.closeDeal({ idDeal: purchaseOrder.negotiation.inspection.deal.id, idClosingReason: successful?.[0]?.id })
  }

  async updateDocument (id) {
    if (!id) return
    const document = await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { type: { name: { _eq: 'sale_purchase_contract' } } }] },
      force: true,
    })

    if (document?.[0]?.id) {
      const documentStatus = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'document' } } }, { status: { name: { _eq: 'pending_buyer' } } }] },
      })
      await this.pushData({
        model: 'Document',
        fields: { id: document?.[0]?.id, id_process_status: documentStatus?.[0]?.id },
      })
    }
  }

  async setPaymentOrderValidator () {
    const { paymentOrder } = this

    await Promise.all(paymentOrder?.map(async order => {
      await this.pushData({
        model: 'PaymentOrder',
        fields: {
          id: order.id,
          id_validator: this.idEmployee,
        },
      })
    }))
  }

  get change () {
    const { formData, panels, section } = this

    return stringifySafe([formData, panels, section])
  }

  get bindClient () {
    const { fields: { client } } = this

    if (!client) return {}

    return { ...client.properties, items: client.items }
  }

  get bindOwners () {
    const { fields: { owners } } = this

    if (!owners) return {}

    return { ...owners.properties, items: owners.items }
  }

  get bindSigners () {
    const { fields: { signers } } = this

    if (!signers) return {}

    return { ...signers.properties, items: signers.items }
  }

  get isDigitalContract () {
    const { formData: { documentType } } = this
    return documentType?.name === 'digital_contract'
  }

  get labelDocument () {
    const { formData: { documentType }, fileInfo } = this

    this.setProperties(fileInfo, documentType.name, 'buyFile')
    return `Comprobante de ${documentType?.description}`
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
    }
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  get totalExpenses () {
    const { formData: { expenses, price } } = this

    if (!expenses?.payments?.length || !price) return 0

    return expenses.payments.reduce((acc, payment) => acc + parseToNumber(payment?.amount), 0)
  }

  get isPurchasePriceOver () {
    const { formData: { price }, negotiation } = this

    return parseToNumber(price) > parseToNumber(negotiation?.negotiationResponse?.value)
  }

  get priceError () {
    const { isPurchasePriceOver } = this

    return isPurchasePriceOver ? 'El monto de compra no puede ser mayor al autorizado' : ''
  }

  get isPurchaseApproved () {
    const { purchaseOrder } = this
    return purchaseOrder?.status?.isApproved
  }

  get expenseError () {
    const { formData: { price, expenses }, isExpenseFull, totalExpenses, isOverPrice } = this

    if (isOverPrice) return 'El gasto no puede ser mayor al monto de la compra'

    if (!expenses?.payments?.length) return ''
    return !isExpenseFull ? '' : `Se debe cubrir el monto de compra ${fixPrice(parseToNumber(price) - parseToNumber(totalExpenses))}`
  }

  get isExpenseFull () {
    const { formData: { price }, totalExpenses } = this

    if (!totalExpenses) return false

    return parseToNumber(totalExpenses) < parseToNumber(price)
  }

  get isOverPrice () {
    const { formData: { price }, totalExpenses } = this

    if (!totalExpenses) return false

    return parseToNumber(totalExpenses) > parseToNumber(price)
  }

  get needNotarizedAuthorization () {
    const { formData: { owners, signers } } = this

    if (!signers?.length) return false

    return signers.some(signer => !owners?.some(owner => signer?.id === owner?.id))
  }

  enablePayment (item) {
    return item.id && item.status && (item.status.name !== 'closed' && item.status.name !== 'pending_payment') && !this.isPurchaseApproved
  }

  confirmRemoveExpense (item) {
    this.purchase = {
      open: true,
      title: '¿Está seguro que desea eliminar el gasto?',
      item,
    }
  }

  @Watch('formData.buyFile', { immediate: true }) onBuyChange (val) {
    const { isDigitalContract, backup } = this
    if (isDigitalContract && val?.length) {
      this.formData.linkContract = val?.[0]?.sourceLink || val?.[0]?.file?.sourceLink || backup?.purchaseForm?.linkContract
    }
    if (!val?.length && !backup?.purchaseForm?.linkContract) {
      this.formData.linkContract = null
    }
  }

  @Watch('formData.legalReport', { immediate: true }) onLegalReportChange (val) {
    const { backup } = this
    if (val?.length) {
      this.formData.linkLegalReport = val?.[0]?.sourceLink || val?.[0]?.file?.sourceLink || backup?.purchaseForm?.linkLegalReport
    }
    if (!val?.length && !backup?.purchaseForm?.linkLegalReport) {
      this.formData.linkLegalReport = null
    }
  }

  get formReady () {
    const { formData, loadingForm } = this

    return {
      formData,
      loadingForm,
    }
  }

  @Watch('formReady', { immediate: true, deep: true })
  @Debounce(1000)
  onPriceChange () {
    this.saveBackup()
  }

  get calculatedExpenses () {
    const { formData: { expenses } } = this

    return expenses?.payments || []
  }

  get signers () {
    const { formData: { signers, owners } } = this

    if (!signers?.length) return owners || []

    return signers
  }

  get disabledPurchase () {
    const { purchaseOrder } = this

    return purchaseOrder?.status?.isApproved
  }

  get showValidationDocument () {
    return true
  }

  async handleFilesValidation () {
    const {
      formData: {
        legalReport,
        isLegalReport,
        commentLegalReport,
        buyFile,
        isContract,
        commentContract,
      },
    } = this

    await this.insertFileProcessInfo({
      id: legalReport[0].id,
      comment: commentLegalReport,
      valid: isLegalReport,
      validation_type: 'manual',
    })
    await this.insertFileProcessInfo({
      id: buyFile[0].id,
      comment: commentContract,
      valid: isContract,
      validation_type: 'manual',
    })
  }

  async openOrder (item) {
    this.paymentSelected = await this.fetchData({
      query: { name: 'fetch', model: 'Payment', params: { id: item.id } },
      force: true,
    })
    this.openPayment = true
    this.paymentPending = ''
  }

  @Watch('openPayment', { immediate: true, deep: true })
  async onOpenPaymentChange (val) {
    const { purchaseOrder, loadingForm } = this

    if (loadingForm) return

    if (!val && purchaseOrder?.id) {
      this.paymentOrder = await this.fetchData({
        query: { name: 'find', model: 'PaymentOrder' },
        filter: { _and: [{ id_process_record: { _eq: this.purchaseOrder.id } }, { id_process: { _eq: this.idProcess } }] },
        force: true,
      })
      this.formData.expenses = this.paymentOrder?.[0]
    }
  }

  get isAnswered () {
    const { formData: { isContract, isLegalReport }, calculatedExpenses } = this

    const isPaymentPending = calculatedExpenses.some(order => !order.status.isPendingPayment || !order.status.isToUpdating)

    return isContract && isLegalReport && !isPaymentPending
  }
  }
